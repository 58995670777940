$theme-color: #1677ff;
$theme-color-opposite: #B70F39;
$underline-color: #1677ff;
$boder-lightgrey: #efefef;


$theme-green: #5cb100;
$theme-orange: #ec661f;
$theme-blue: #4fb0dc;
$theme-purple: #6b53ab;
$theme-orange-light: #fd9800;
$theme-teal: #0fb78d;


$hover-lightgreen: #0069fc;
$red: #dc3545;
$green: #28a745;
$grey: #d3d3d3;
$grey-darker: rgb(153, 153, 153);

$yt-red: #5E3647;
$yt-blue-light: #287EAB;
$yt-blue-dark: #054663;
$yt-blue-darker: #0B232B;
$yt-green-light: #7BAC8E;
$yt-green: #2A978D;
$yt-yellow: #E6F145;
$yt-orange: #FEE579;

$submit-color: #00ff29;
$main-margin: 10px;
// $logo-color: #0068ca;
// $logo-color2: #1890ff;
$logo-color: #3f6ad8;
$logo-color-light: #3f6ad877;
$logo-color2: #3f6ad8;
$color-div: #fafafa;
$color-div-font: rgb(25, 25, 25);
$color-div-border: linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25));
$color-bg-fade: #ffffff80;
$main-bg: url('./assets/img/main_bg.jpeg');

$size-mobile: 850px;
$size-mobile-2: 650px;

$dash-background: #F1F4F6;

$menu-width: 16rem;
$menu-margin: 1rem;
$menu-radius: 1rem;
$menu-zIndex: 1000;
$popup-zIndex: 2000;
$zMenu-openClose: 900;
$footer-zIndex: 1900;
$footer-height: 1rem;

$window_maxH: 1200px;
$window_width: 1080px;
$window_width_content: 900px;
$window_width_content_smaller: 600px;

$header_height: 40px;
$header_zindex: 10;
$footer_height: 1rem;

$bs-timeline-icon-size: 38px;
$bs-timeline-icon-space: 0.35rem;