@import "variables";

@font-face {
  font-family: 'Raleway-italic';
  src: local('Raleway-italic'), url(./assets/fonts/Raleway-Italic-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway-italic'), url(./assets/fonts/Raleway-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'LibreBaskerville-bold';
  src: local('LibreBaskerville-bold'), url(./assets/fonts/LibreBaskerville-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'LibreBaskerville-italic';
  src: local('LibreBaskerville-italic'), url(./assets/fonts/LibreBaskerville-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'LibreBaskerville';
  src: local('LibreBaskerville'), url(./assets/fonts/LibreBaskerville-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Mulish-italic';
  src: local('Mulish-italic'), url(./assets/fonts/Mulish-Italic-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'Mulish';
  src: local('Mulish'), url(./assets/fonts/Mulish-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'EastSeaDokdo';
  src: local('EastSeaDokdo'), url(./assets/fonts/EastSeaDokdo-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Chilanka';
  src: local('Chilanka'), url(./assets/fonts/Chilanka-Regular.ttf) format('truetype');
}

html {
  scroll-behavior: smooth;
}


body {
  margin: 0;
  font-family: 'Mulish';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  min-height: 2rem;
  border: 2px solid black;
  background-color: white;
  color: #777676;
  text-align: left;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 400;
}

input,
textarea {
  height: 2rem;
  line-height: 1rem;
  border-radius: 0.25rem;
  width: 300px;
  padding: 0 5px;
  max-width: 90%;
}

button {
  text-align: center;
  cursor: pointer;
  background: $theme-color;
  color: white;

  &:hover {
    color: black;
    background: white;
  }
}


a:hover {
  color: $theme-color;
}